<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="31.3" x2="151.7" y1="91.5" y2="91.5">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <rect :fill="background" height="183" width="183"/>
     <path d="M92.8,65.24v6.4a1.5,1.5,0,0,1-3,0v-6.4a1.5,1.5,0,0,1,3,0Zm58.9,15.31v39.6a15,15,0,0,1-15,15H46.3a15,15,0,0,1-15-15V80.55a15,15,0,0,1,15-15h27a17.59,17.59,0,0,1,.2-2.44,15.93,15.93,0,0,1,.36-1.75c.15-.59.32-1.16.52-1.71a18.55,18.55,0,0,1,1.75-3.51,19.12,19.12,0,0,1,1.14-1.58,16.21,16.21,0,0,1,1.3-1.45,18.83,18.83,0,0,1,3-2.44c.28-.18.56-.35.84-.5a17.05,17.05,0,0,1,2.67-1.24l.84-.28a17.74,17.74,0,0,1,5.34-.8,18.14,18.14,0,0,1,6.19,1.08,20,20,0,0,1,2,.88,18.86,18.86,0,0,1,2.73,1.74,18.37,18.37,0,0,1,2.39,2.19h0c.38.42.75.86,1.1,1.32s.67,1,1,1.45a17.13,17.13,0,0,1,.83,1.53,17.72,17.72,0,0,1,1.77,7.5H136.7A15,15,0,0,1,151.7,80.55Zm-79.27,11H67.79v7H61v-7H56.34v17.83H61v-7.2h6.8v7.2h4.64ZM79.3,65.85a12,12,0,0,0,24,0,2.81,2.81,0,0,0,0-.3,12,12,0,0,0-24,0A2.81,2.81,0,0,0,79.3,65.85Zm13.28,43.5L86.34,91.52H80.78l-6.27,17.83h4.67l1.25-4h6.15l1.22,4ZM109,91.52h-4.73L101,102.35,100,105.8l-1-3.5L95.79,91.52H90.9l6.35,17.83h5.45Zm18.36,8.91a10.74,10.74,0,0,0-1-4.93,7.33,7.33,0,0,0-3-3.15,10.44,10.44,0,0,0-9.33,0,7.27,7.27,0,0,0-3,3.15,12.1,12.1,0,0,0,0,9.86,7.32,7.32,0,0,0,3,3.15,10.44,10.44,0,0,0,9.33,0,7.39,7.39,0,0,0,3-3.15A10.8,10.8,0,0,0,127.38,100.43ZM84.31,97.89l-.78-2.81h0l-.73,2.78-1.35,4.29h4.21Zm37.3-1.59a3.4,3.4,0,0,0-3-1.41,3.44,3.44,0,0,0-3,1.41,8.8,8.8,0,0,0,0,8.26,3.45,3.45,0,0,0,3,1.4,3.42,3.42,0,0,0,3-1.4,8.87,8.87,0,0,0,0-8.26ZM105.72,55.07c-.35-.46-.72-.9-1.1-1.32A13.74,13.74,0,0,1,105.72,55.07Zm1.8,3a17.13,17.13,0,0,0-.83-1.53A14.08,14.08,0,0,1,107.52,58.05ZM92.77,59.76a1.52,1.52,0,0,0-.09-.28,1,1,0,0,0-.14-.25,1.16,1.16,0,0,0-.18-.24,2.11,2.11,0,0,0-.23-.19,1.26,1.26,0,0,0-.26-.13,2.29,2.29,0,0,0-.28-.09,1.49,1.49,0,0,0-1.35.41.92.92,0,0,0-.19.24,1,1,0,0,0-.14.25,1,1,0,0,0-.08.28,1.45,1.45,0,0,0,0,.29,1.39,1.39,0,0,0,.12.57,1.18,1.18,0,0,0,.33.49,1.5,1.5,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.27,1.27,0,0,0,.32-.49,1.39,1.39,0,0,0,.12-.57A1.43,1.43,0,0,0,92.77,59.76Z" style="fill:url(#Naamloos_verloop_4);"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>